import React from 'react';
import { Col, Row, Divider } from 'antd';

import justice from "../../assets/img/justiceIsBlind.jpg";
import ulemjProfile from '../../assets/img/11882398_987395497978931_924467307339503159_o.jpg';


const AboutPage = () => {
  return (
    <Row justify={"center"} className="about-page">
      <Col xl={18} lg={20} md={24} sm={24} xs={24}>
        <Row justify={"center"} gutter={[20, 20]}>
          <Col span={24}>
            <Row justify={'center'}>
              <h1 className='page-title'>About us</h1>
            </Row>
          </Col>
          <Divider></Divider>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Row justify={'center'}>
              < Row justify={"center"} align={'middle'}>
                <Col span={23} className='intro-main'>
                  <strong>
                    <p className='bolded'>
                      Established in 1999, our organization, formerly known as the "SOD Law Bureau," was founded with a singular purpose — safeguarding the legal interests of citizens and providing unwavering legal support.
                    </p>
                  </strong>
                  <p>
                    Comprising seasoned lawyers and advocates, each possessing a minimum of a decade's expertise in business law, international law, civil law, administrative law, and criminal law, our legal group stands as a paragon of experience and proficiency.
                  </p>
                  <p>
                    Embracing a commitment to excellence, our law firm, based in Mongolia, is at the forefront of legal endeavors. Our esteemed team of around 10 lawyers specializes in seven distinct realms of legal practice.
                  </p>
                </Col>

              </Row >

            </Row>
          </Col>
          <Col  xl={12} lg={24} md={24} sm={24} xs={24}>
            <Row justify={'center'} className='image-profile'>
              <img src={ulemjProfile} alt='ulemj profile' ></img>
            </Row>
          </Col>
          <Divider></Divider>
        </Row>
        <Row justify={'center'}>
          <Col xl={12} lg={16} md={20} sm={20} xs={22}>
            <Row justify={"center"} className="about-list">
              <Col span={23}>
                <h1>Our Range of Services</h1>
              </Col>
              <ul>
                <li>
                  <strong>Legal Assistance in Civil Disputes:</strong> Our legal experts specialize in navigating and resolving civil disputes, offering comprehensive support to protect your rights and interests.
                </li>
                <li>
                  <strong>Foreign Investment Law:</strong> Stay well-versed in foreign investment regulations with our legal guidance, ensuring compliance and facilitating your ventures.
                </li>
                <li>
                  <strong>Visa and Residence Permit Services:</strong> Let us assist you in obtaining Mongolian visas and permanent residence permits, providing a seamless process for your legal residency needs.
                </li>
                <li>
                  <strong>Legal Support in Criminal Cases:</strong> Our experienced team is ready to provide robust legal defense and assistance in navigating complex criminal cases.
                </li>
                <li>
                  <strong>Administrative Dispute Resolution:</strong> Receive expert legal assistance in administrative matters, ensuring fair and just resolutions to disputes.
                </li>
                <li>
                  <strong>Notary Services:</strong> Trust our notary services for the authentication and certification of legal documents, adding an extra layer of credibility to your transactions.
                </li>
                <li>
                  <strong>Accounting and Financial Services:</strong> Beyond legal expertise, we offer comprehensive accounting and financial services, providing a holistic approach to meet your business needs.
                </li>
              </ul>
            </Row>
          </Col>
          <Col xl={12} lg={16} md={20} sm={20} xs={22}>
            <Row justify={"start"} className="about-list">
              <Col span={23}>
                <h1>Strategic Partnerships with Distinguished Organizations</h1>
              </Col>
              <ul>
                <li>
                  <strong>Chinese CCP:</strong> We're collaborating with the Chinese Communist Party, fostering strong ties for mutual growth and cooperation.
                </li>
                <li>
                  <strong>Foreign Investment Law:</strong> Our expertise extends to advising on foreign investment laws, providing valuable insights for strategic decision-making.
                </li>
                <li>
                  <strong>South Korea Samsung Corporation Representative Office:</strong> Building on a relationship of trust, we work closely with the representative office of South Korea's Samsung Corporation.
                </li>
                <li>
                  <strong>Jiangsu Jiandu Construction, China:</strong> Partnering with Jiangsu Jiandu Construction in China, we contribute legal support for seamless operations.
                </li>
                <li>
                  <strong>"L'Adilax" LLC:</strong> In collaboration with "L'Adilax" LLC, we offer legal expertise to support their business endeavors.
                </li>
                <li>
                  <strong>"Nord Benz" Representative Office:</strong> We proudly collaborate with the "Nord Benz" representative office, providing legal counsel for their strategic initiatives.
                </li>
                <li>
                  <strong>Korea Wireless Holding, Republic of Korea:</strong> Strengthening ties with Korea Wireless Holding, we offer legal support for their endeavors in the Republic of Korea.
                </li>
                <li>
                  <strong>"EDN" LLC:</strong> Our partnership with "EDN" LLC involves comprehensive legal collaboration, ensuring their legal interests are safeguarded.
                </li>
                <li>
                  <strong>"Montperlite" LLC:</strong> Working hand-in-hand with "Montperlite" LLC, we provide legal assistance to facilitate their business operations.
                </li>
                <li>
                  <strong>Mongolian Yellow Book:</strong> We proudly collaborate with the Mongolian Yellow Book, contributing legal expertise to enhance their services.
                </li>
              </ul>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AboutPage




