import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "antd";



const HomePage = () => {
  const navigate = useNavigate()

  const gotoServices = () =>{
    navigate('/services')
  }
  return (
    <>
      <Row justify={"center"}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "30%",
            height: "10%",
          }}
        >
          {/* <img src={bgPhoto} className="bgHomePage" alt="bgPhoto"></img> */}
          <div className="outer-container">
            <div className="overlay"></div>
            <div className="inner-container">
              <p>In matters of advice and company, consider me your legal compass,<br></br>guiding you through the complexities with steadfast support.</p>
              <Row justify={"center"}>
                <Button type="primary" onClick={gotoServices}>
                  <strong>Services</strong>
                </Button>
              </Row>
            </div>
          </div>

        </Col>
      </Row>
      {/* */}
    </>
  );
};

export default HomePage;
