import React from "react";
import { Layout, Col, Row, Divider } from "antd";

import addressImg from '../../assets/img/contact_address.png'

const Contact = () => {
  return (
    <Row justify={"center"} className="contact-page">
      <Col xl={20} lg={21} md={22} sm={23} xs={23}>
        <Row justify={"center"}>
          <Col xl={12} lg={12} md={20} sm={23} xs={23}>
            <Row justify={"center"}>
              <Col span={20}>
                <div className="addres-img">
                  <img src={addressImg} alt="Adrres missing"></img>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={20} sm={23} xs={23} className="main-contact-section">
            <Row justify={"center"}>
              <Col span={20} className="blue-contact">
                <div>
                  <h3>Let's get started</h3>
                  <h1>CONTACT US</h1>
                </div>
                <div>
                  <p>At <strong>Ulemj Partners LLP</strong>,</p>
                  <p>
                    we are committed to providing expert guidance
                    and upholding the principles of justice. If you find yourself in need
                    of legal counsel or assistance,
                    we invite you to get in touch with our seasoned professionals.
                    Your legal well-being is our priority.
                  </p>
                </div>
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col span={20} className="white-contact">
                <Row justify={"space-around"}>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24} className="address">
                    <div>
                      <h1>Address</h1>
                    </div>
                    <div>
                      <p>Mongolia Ulaanbaatar. Seoul street-12, </p>
                      <p>Sukhbaatar district</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24} className="contact-info">
                    <div>
                      <h1>Reach us</h1>
                    </div>
                    <div>
                      <p><i>Phone us</i>: 91112603</p>
                      {/* <p><i>Phone us</i>: 91112603</p> */}
                      <p><i>Email us</i>: ulemjs@gmail.com</p>
                      <p><i>Office Hours: </i>9 AM - 18PM GMT+8</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>

  )
}

export default Contact;