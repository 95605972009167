import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "antd";
import MainHeader from "./main.header";
import MainFooter from "./main.footer";
import HomePage from '../Home/Home';
import Contact from "../contact/contact";
import Services from "../services/services";
import AboutPage from "../about/about";

const { Content } = Layout;

const MainLayout = () => {
  return (
    <div>
      <Layout>
        <MainHeader />
        <Content
          style={{
            minHeight: "calc(100vh - 150px)",
            backgroundColor: "rgb(248, 248, 248)",
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route element={<HomePage />} path="/home"></Route>
            <Route element={<Contact></Contact>} path="/contact"></Route>
            <Route element={<Services />} path="/services"></Route>
            <Route element={<AboutPage />} path="/aboutus"></Route>
          </Routes>
        </Content>
        <MainFooter />
      </Layout>
    </div>
  );
};

export default MainLayout;
