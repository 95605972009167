import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Divider, Button } from "antd";
import ServicesComponent from "../../components/services";

const services = [
  {
    title: "Simple Service",
    price: "Package price \n 80,000 ₮",
    items: ["Provide Legal Advice to individuals and legal entities"],
  },
  {
    title: "Advanced Service",
    price: "Package price \n 120,000 ₮",
    items: ["Providing Legal opinions on perticular legal issues /for once/"],
  },
  {
    title: "Business start-up Service",
    price: "Package price \n 200,000 ₮",
    items: [
      "Drafting necessary legal documents related with Registration and establishing new company and Reorgonization of Company",
      ,
      "Represent legal entities to the Government Organization in relation to registration of legal entities",
    ],
  },
  {
    title: "Business Service",
    price: "Package price \n 300,000 ₮",
    items: [
      "Providing legal opinions on particular legal issues /Two times/",
      "Review the transactions and contracts /Two times/",
    ],
  },
  {
    title: "Premium Service",
    price: "Package price \n 500,000 ₮",
    items: [
      "Draft and review the legal documents and provide legal advice and written opinions on day to day operations of client's business",
    ],
  },
  {
    title: "Premium Plus Service",
    price: "Package price \n 600,000 ₮",
    items: ["Acted as Attorney and represented on labor disbutes /Two times/"],
  },
];

const Tiers = [
  {
    title: "V.I.P Service",
    price: "Per month \n 700,000 ₮",
    negotiable: false,
    items: [
      "Provide Written legal opinions on particular issues /Unlimited/",
      "Acted as Attorney and represented client on labor disputes /For once/",
      "Provide Legal training to clients Organization in each quarter (for employees)",
      "Provide Legal Advice and information on registration of legal entities,registration of any changes of legal entities",
      "Represent Legal entities to the Government and non-government Organization in relation to Registration of legal entities",
      "To prepare Financial reports",
    ],
  },
  {
    title: "Platinum Service",
    price: "Per month \n 1,200,000 ₮",
    negotiable: false,
    items: [
      "Review daily legal documents sudh as resolutions and orders of legal entity",
      "Act as Attorney and represented client on labor disbutes /for once/",
      "Provide legal advice on important legal matters and provide risk assessment",
      "Provide Legal training to clients Organization in each quarter (for employees)",
      "Represent client to the Registration office in relation office in relation to the registration of \n the change of shareholders and partners of legal entity",
      "Have responsible on legal matters of legal entity by accommodating professional \n staff to clients office once or  twice a week",
    ],
  },
  {
    title: "Unlimited Service",
    price: "Per month \n 1,700,000 ₮",
    negotiable: true,
    items: [
      "provide Legal services on enforcement to the intellectual and porperty rights of legal entities",
      "Act as Attorney and represented client on labor disbutes /Unlimited/",
      "Draft Legal documents and provide legal advice on merger, consolidation, divison and seperation of company, Reorganization and Bankruptcy",
      "Provide the legal training with requested topic from client to their employees for once or twice a week",
      "Have responsible on legal matters of legal entity my accommodating professional staff to client office permanently",
      "Provide legal advices and supports to the client particular research, analysis and projects",
      "Provide legal analysis on particular issues as requested from client",
    ],
  },
];

const Services = () => {
  const navigate = useNavigate();

  const contact = () => {
    navigate("/contact");
  };

  return (
    <Row justify={"center"} className="services">
      <Col xl={18} lg={23} md={24} sm={24} xs={24}>
        <Row justify={"center"} gutter={[20, 40]}>
          <Col xl={12} lg={12} md={20} sm={23} xs={23}>
            <Col span={24}>
              <Row justify={"center"}>
                <h1>Services</h1>
              </Row>
            </Col>
            <Divider />
            {services.map((service, index) => (
              <ServicesComponent service={service} />
            ))}
          </Col>
          <Col xl={12} lg={12} md={20} sm={23} xs={23}>
            <Col span={24}>
              <Row justify={"center"}>
                <h1>Plans</h1>
              </Row>
            </Col>
            <Divider />
            {Tiers.map((service, index) => (
              <ServicesComponent service={service} />
            ))}
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: 30 }}>
          <Button type="primary" className="contact-button" onClick={contact}>
            Contact us
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Services;
