import React, { useState } from "react";
import { Menu, Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import Logo from '../../assets/img/image.png';

const items = [
  { label: "Home", key: 1, path: "/home" },
  { label: "Services", key: 2, path: "/services" },
  { label: "About Us", key: 3, path: "/aboutus" },
  { label: "Contact", key: 4, path: "/contact" },
];

const MainHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentActive , setActive] = useState(1)

  const goHome = () => {
    navigate("/");
  };

  const onClick = (e) => {
    navigate(items[e.key - 1].path);
  };

  const getCurrentKey = () => {
    const currentPath = location.pathname.substr(1);
    const currentItem = items.find((item) => item.path === currentPath);
    console.log(currentItem)
    return currentItem ? currentItem.key.toString() : '1';
  };

  return (
    <header className="header">
      <Row justify={"center"} id="header-large">
        <Col xl={20} lg={20} md={20} sm={24} xs={24}>
          <Row justify={'center'}>
            <Col span={2}>
              <Row align={"middle"} justify={"center"} style={{ display: "flex", alignItems: "center" }}>
                <img style={{ width: 60, paddingTop: 10 }} src={Logo} alt="logo" onClick={goHome}></img>
              </Row>
            </Col>
            <Col span={10}>
              <h1 onClick={goHome} style={{ paddingTop: 5 }}>Ulemj Partners LLP</h1>
            </Col>
            <Col span={12} >
              <Row justify={"center"}>
                <Menu
                  onClick={onClick}
                  style={{ backgroundColor: "#040F0F", height: 70, justifyContent: "flex-end", alignItems: 'center', marginTop: 15 }}
                  mode="horizontal"
                  disabledOverflow={true}
                  theme="dark"
                  items={items}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify={"center"} id="header-small">
        <Col xl={20} lg={12} md={18} sm={24} xs={24}>
          <Row justify={'center'}>
            <Col span={5}>
              <Row align={"middle"} justify={"center"} style={{ display: "flex", alignItems: "center" }}>
                <img style={{ width: 60, paddingTop: 10 }} src={Logo} alt="logo" onClick={goHome}></img>
              </Row>
            </Col>
            <Col span={19}>
              <h1 onClick={goHome} style={{ paddingTop: 5 }}>Ulemj Partners</h1>
            </Col>
            <Col span={24} >
              <Row justify={"center"}>
                <Menu
                  onClick={onClick}
                  style={{ backgroundColor: "#040F0F", height: 70, justifyContent: "flex-end", alignItems: 'center', marginTop: 15 }}
                  mode="horizontal"
                  disabledOverflow={true}
                  theme="dark"
                  items={items}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export default MainHeader;
