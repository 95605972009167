import React from "react";
import { Layout, Col, Row, Divider } from "antd";

const { Footer } = Layout;

const MainFooter = () => {
  return (
    <Footer className="site-footer" style={{ backgroundColor: "#040F0F", color: "white" }}>
      <Row justify={"center"}>
        <Col xl={16} lg={20} md={24} sm={22} xs={22}>
          <Row justify={"center"} align={"top"}>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <div className="footer-address">
                <h4>Address :</h4>
                <p>Mongolia Ulaanbaatar. Seoul street-12, Sukhbaatar district</p>
              </div>
            </Col >
            <Col xl={8} lg={8} md={12} sm={12} xs={24}>
              <div className="footer-socials">
                <h4>Social Media :</h4>
                <a href="https://www.facebook.com/ulemjpartners" target="_blank">
                  <i className="fa">&#xf09a;</i> &nbsp; Facebook </a>
              </div>
            </Col >
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <h4>Contact us :</h4>
              <div className="footer-socials">
                <p><i>Phone :</i> 91112603</p>
                {/* <p><i>Phone us</i>: 91112603</p> */}
                <p><i>Email :</i> ulemjs@gmail.com</p>
              </div>
            </Col >
          </Row>
        </Col>
      </Row>
      <Row justify={"center"}>
        <p style={{ fontSize: 8 }}>@SocLite 2023</p>
      </Row>

    </Footer>
  );
};

export default MainFooter;
